<template>
  <div id="singleModule" style="background: #22252C;height: 240px;">
    <div class="moduleContainer footer">
      <div class="">
        <i class="iconfont wemeet-dibulogo wemeetLogo"></i>
      </div>
      <!-- 中间 -->
      <!-- <div style="display:flex">

        <div class="lineBox">
          <p class="title">客服热线</p>
          <p class="desc">+86 19145516929 </p>
          <p class="title">服务时间</p>
          <p class="desc">09:00-18:00</p>
        </div>

        <div class="lineBox">
          <p class="title">关于我们</p>
          <p class="desc" @click="goAboutUs('profile')">公司简介</p>
          <p class="desc" @click="goAboutUs('environment')">办公环境</p>
          <p class="desc" @click="goAboutUs('develop')">发展大事记</p>
          <p class="desc" @click="goAboutUs('position')">联系我们</p>
        </div>

        <div class="lineBox">
          <p class="title">商务合作</p>
          <p class="desc">邮箱地址</p>
        </div>

        <div class="lineBox">
          <p class="title">官方公告</p>
          <p class="desc" @click="openDocument('https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/%E7%9B%B4%E6%92%AD%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf')">直播服务协议</p>
          <p class="desc" @click="openDocument('https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/%E7%9B%B4%E6%92%AD%E5%85%AC%E7%BA%A6.pdf')">直播公约</p>
          <p class="desc" @click="openDocument('https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/%E7%9B%B4%E6%92%AD%E6%89%BF%E8%AF%BA%E4%B9%A6.pdf')">文明直播承诺书</p>
          <p class="desc" @click="openDocument('https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/%E7%BA%A0%E7%BA%B7%E5%A4%84%E7%90%86.pdf')">纠纷处理</p>
        </div>

        <div class="qrCode">
          <div>
            <p class="title">公众号二维码</p>
            <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/qrCode.png" alt="公众号二维码">
          </div>
          <div>
            <p class="title">小程序二维码</p>
            <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/qrCodeByMini.png" alt="小程序二维码">
          </div>
        </div>

      </div> -->

    </div>

    <!-- 底部信息 -->
    <div class="fixedBottom">
      <p>版权 © 海南会多多信息技术有限公司</p>
      <p> <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">琼ICP备2021004082号-1 </a> ​公司地址：海南省海口市龙华区南沙路47号通信广场二楼K007 </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Wmtfooter',

  data() {
    return {}
  },

  mounted() {},

  methods: {
    openDocument(e) {
      window.open(e)
    },
    goAboutUs(address) {
      this.$router.replace({ path: `/aboutUs?t=${new Date().getTime()}#${address}` })
    },
    goIcp() {
      window.open('')
    },
  },
}
</script>

<style lang="less" scoped>
.footer {
  padding-top: 20px;
}
.wemeetLogo {
  font-size: 80px;
  color: #fff;
}
.moduleContainer {
  display: flex;
  justify-content: center;
}
.fixedBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px;
  border-top: 1px solid #373b40;
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888;
  padding-top: 22px;
  p {
    margin-bottom: 10px;
  }
  a {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888;
    margin-right: 10px;
  }
}
.lineBox {
  margin-right: 50px;
  .title {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  .desc {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
    margin-top: 14px;
    margin-bottom: 14px;
    cursor: pointer;
  }
}
.qrCode {
  display: flex;
  margin-left: 50px;
  .title {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  div {
    margin-right: 20px;
  }
}
</style>