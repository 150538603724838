<template>
  <div>
    <WmtHeader :activeKey='activeKey'></WmtHeader>
    <div id="singleModule" style="height:auto;">
      <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/aboutUs/topBg.png" alt="" class="topbg">
    </div>
    <!-- 公司简介 -->
    <div id="profile"></div>
    <CompanyDescVue></CompanyDescVue>

    <!-- 企业文化 -->
    <CompanyCultUre></CompanyCultUre>

    <!-- 发展历程 -->
    <div id="develop"></div>
    <TimeLine></TimeLine>
    <!-- 公司环境 -->
    <div id="environment"></div>
    <CompanyHj></CompanyHj>

    <!-- 联系我们 -->
    <div id="position"></div>
    <ContactUs></ContactUs>
    <!-- 底部通栏 -->
    <WmtFooter></WmtFooter>
  </div>
</template>

<script>
import WmtHeader from '@/components/layout/wmtHeader.vue'
import WmtFooter from '@/components/layout/wmtFooter.vue'
import CompanyDescVue from './companyDesc.vue'
import CompanyCultUre from './companyCultUre.vue'
import ContactUs from './contactUs.vue'
import CompanyHj from './companyHj.vue'
import TimeLine from './timeLine/index.vue'
export default {
  name: 'Home',
  components: {
    WmtHeader,
    WmtFooter,
    CompanyDescVue,
    CompanyCultUre,
    ContactUs,
    CompanyHj,
    TimeLine,
  },
  data() {
    return {
      activeKey: 'aboutUs',
    }
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
.topbg {
  width: 100%;
  height: 210px;
}
</style>