import { render, staticRenderFns } from "./moduleTitle.vue?vue&type=template&id=ff120318&scoped=true&"
import script from "./moduleTitle.vue?vue&type=script&lang=js&"
export * from "./moduleTitle.vue?vue&type=script&lang=js&"
import style0 from "./moduleTitle.vue?vue&type=style&index=0&id=ff120318&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff120318",
  null
  
)

export default component.exports