<template>
  <div id="singleModule" style="background: #F5F9FA;height:auto;">
    <div class="moduleContainer">
      <ModuleTitle :title='title'></ModuleTitle>

      <div class="news">
        <div v-for="(item, index) in culture" :key="index" class="item" @mouseenter="hoveIn(index)" @mouseleave="hoveOut(index)">
          <div class="dialog" :class="[ isShow ? 'fade' : '']" v-if="isShow && checkIndex == index"> {{ item.smallWord }}</div>
          <div class="center">
            <i class="iconfont" :class="item.iconfont"></i>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </div>

    </div>
  </div>
  </div>
</template>

<script>
import ModuleTitle from './moduleTitle.vue'
export default {
  name: 'companyDesc',
  components: {
    ModuleTitle,
  },
  data() {
    return {
      title: '企业文化',
      autoPlay: false,
      isShow: false,
      checkIndex: 99,
      culture: [
        {
          name: '使命',
          iconfont: 'wemeet-shiming',
          smallWord: '办好每个会  WeMeet For Good',
        },
        {
          name: '愿景',
          iconfont: 'wemeet-yuanjing',
          smallWord: '打造全新商务互联平台',
        },
        {
          name: '价值观',
          iconfont: 'wemeet-jiazhiguan',
          smallWord: '开放，创新，认真，极致',
        },
      ],
    }
  },
  methods: {
    hoveIn(index) {
      console.log('我进来了')
      this.isShow = true
      this.checkIndex = index
    },
    hoveOut() {
      console.log('我出去了')
      this.isShow = false
      this.checkIndex = 99
    },
  },
}
</script>

<style lang="less" scoped>
.moduleContainer {
  padding-bottom: 117px;
}
.fade {
  animation: show .5s;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.desc {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  p {
    margin-bottom: 20px;
  }
  .companyDoor {
    width: 100%;
    height: 400px;
    margin-top: 50px;
  }
}

.news {
  display: grid;
  /* grid-gap: 30px 50px */
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  /* 一行放两列、两个auto */
  grid-template-columns: auto auto auto;
  cursor: pointer;
  .item {
    width: 390px;
    height: 340px;
    background: #ffffff;
    text-align: center;
    position: relative;
    .dialog {
      width: 390px;
      height: 340px;
      background: rgba(90, 190, 193, 0.9);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 340px;
    }
    .center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .iconfont {
      font-size: 76px;
      color: #5abec1;
      margin-bottom: 14px;
    }
    .name {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
}
</style>