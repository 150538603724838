<template>
  <div >
    <h2 class="moduleTitle" :style="{ marginBottom: marginBottom + 'px' }"> {{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: 'moduleTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
    marginBottom: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="less" scoped>
.moduleTitle {
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  text-align: center;
  margin-top: 77px;
}
</style>