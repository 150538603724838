<template>
  <div id="singleModule" style="background: #fff;height:auto;">
    <div class="moduleContainer">
      <ModuleTitle :title='title'></ModuleTitle>

      <div class="tablist">
        <div class="arrow" @click="lastAndNext(-1)">
          <i class="iconfont wemeet-xiangzuo arrowIcon"></i>
        </div>
        <div class="tab" :class="tabIndex==index?'active':''" v-for="(item,index) in timelist" @click="changeIndex(index)">{{item.year}}</div>
        <div class="next arrow" @click="lastAndNext(1)">
          <i class="iconfont wemeet-gengduo arrowIcon"></i>
        </div>
      </div>
      <div class="timeLine">
        <div class="box" v-for="(item,index) in timelist[tabIndex].contentList">
          <div class="topbox" v-if="index%2==1">
            <el-popover placement="top-start" title="" width="200" trigger="hover" :content="item.content">
              <div slot="reference" class="content text-ellipsis-3">{{item.content}}</div>
            </el-popover>
            <div class="month">{{item.month}}</div>
          </div>
          <div class="linebox"></div>
          <div class="bottombox" v-if="index%2!=1">
            <div class="month bottomMonth">{{item.month}}</div>
            <el-popover placement="top-start" title="" width="200" trigger="hover" :content="item.content">
              <div slot="reference" class="content text-ellipsis-3">{{item.content}}</div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleTitle from '../moduleTitle.vue'
export default {
  name: 'timeLine',
  components: {
    ModuleTitle,
  },
  data() {
    return {
      tabIndex: 0,
      title: '发展历程',
      timelist: [
        // {
        //   year: '2023年',
        //   contentList: [
        //     {
        //       content: 'WeMeet用户数突破金沙江创投和金沙江创投和线性资本跟投线性资本跟投金沙江创投和金沙江创投和线性资本跟投线性资本跟投一万百万人',
        //       month: '7月',
        //     },
        //     {
        //       content: 'WeMeet金沙江创投和金沙江创投和线性资本跟投线性资本跟投金沙江创投和金沙江创投和线性资本跟投线性资本跟投',
        //       month: '6月',
        //     },
        //     {
        //       content: 'WeMeet用户数突破一万百万人',
        //       month: '4月',
        //     },
        //     {
        //       content: 'WeMeet用户数突破一万百万人',
        //       month: '3月',
        //     },
        //     {
        //       content: 'WeMeet用户数突破一万百万人',
        //       month: '3月',
        //     },
        //     {
        //       content: 'WeMeet用户数突破一万百万人',
        //       month: '3月',
        //     },
        //   ],
        // },
        {
          year: '2022年',
          contentList: [
            {
              content: '城市级会展服务平台“ME杭州”发布，MEHZ平台是集合了线下展会的城市数字商旅服务平台，具有“一码通行、一键互动、一屏通览”三大特色以及会议管理、城市服务、会员礼遇等各大功能，为参会者提供便捷的数字化城市信息指引，也为会议、展览和活动提供整体数字运营支撑和数字化管理服务。',
              month: '2月',
            },
            {
              content: '推出全球首创“数字会展码”，助力会展业发展',
              month: '4月',
            },
            {
              content: 'WeMeet小程序“办个会”功能上线',
              month: '5月',
            },
            {
              content: '国外城市级会展服务平台“MeetSG”成功上线',
              month: '10月',
            },
            {
              content: '“D平台”发布，助力首届全球数字贸易博览会圆满成功举办',
              month: '12月',
            },
          ],
        },
        {
          year: '2021年',
          contentList: [
            {
              content: '2021-增长引力：会展数字化转型沙龙暨腾讯企点WeMeet联合产品发布会',
              month: '5月',
            },
            {
              content: '杭州国际会展文化活动暨国际会展业创新发展大会圆满举办',
              month: '6月',
            },
            {
              content: '助力2021世界人工智能大会，WeMeet Cube智能会议舱亮相世博展览馆',
              month: '7月',
            },
            {
              content: '全球渔商荟发布，助力中国国际渔业博览会数字化创新',
              month: '11月',
            },
          ],
        },
        {
          year: '2020年',
          contentList: [
            {
              content: 'WeMeet成为腾讯企业级产品服务合作伙伴',
              month: '7月',
            },
            {
              content: 'WeMeet智慧会展团队打造的WeMeet智慧会展项目荣获第七届宝安创新创业大赛行业赛互联网和移动互联网行业“三等奖”',
              month: '9月',
            },
            {
              content: '由WeMeet团队开发的AMS Live网站上线，助力Automechanika Shanghai通过提供形式多样的在线增值服务，全面提升与会各方的参与和买家的采购绩效。利用线下实体展览会与线上展位的全方位联动，突破时间和地域的界限，为全球的参展企业和观众缔造耳目一新的与会体验。',
              month: '9月',
            },
            {
              content: 'WeMeet整体团队正式入驻新办公场地龙阳商务楼4C',
              month: '11月',
            },
          ],
        },
        {
          year: '2019年',
          contentList: [
            {
              content: '海南会多多信息技术有限公司注册成立',
              month: '9月',
            },
            {
              content: '由上海市商务委员会牵头，上海市商务行政事务中心（上海市会展促进中心）直属领导的“e会上海”项目发布，旨在围绕国内外展客商的商旅服务需求，提供“吃住行”“娱购游”为主的城市引导和咨询服务',
              month: '9月',
            },
            {
              content: '商旅系列小程序如拉斯维加斯发布',
              month: '11月',
            },
          ],
        },
      ],
    }
  },
  methods: {
    changeIndex(index) {
      this.tabIndex = index
    },
    lastAndNext(type) {
      console.log(type)
      if (type == -1 && this.tabIndex != 0) {
        this.tabIndex--
      } else if (type == -1 && this.tabIndex == 0) {
        this.tabIndex = this.timelist.length - 1
      } else if (type == 1 && this.tabIndex < this.timelist.length - 1) {
        this.tabIndex++
      } else if (type == 1 && this.tabIndex == this.timelist.length - 1) {
        this.tabIndex = 0
      }
    },
  },
}
</script>

<style scoped lang='less'>
.moduleContainer {
  padding-bottom: 155px;
}
.tablist {
  display: flex;
  border: 1px solid #b3b3b3;
  height: 50px;
  border-radius: 8px;
  width: 1198px;
  margin-bottom: 140px;
  background: #f6f6f6;
  box-sizing: content-box;
}

.tab {
  width: 250px;
  height: 50px;
  border-left: 1px solid #b3b3b3;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 16px;
  font-weight: 600;
}

.tab:hover {
  transition: 0.3s;
  background: rgba(90, 190, 193, 0.5);
  color: #fff;
}

.tab.active {
  background: #5abec1;
  color: #fff;
  transition: 0.4s;
  position: relative;
}
.tab.active::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  width: 250px;
  height: 50px;
  border: 1px solid #5abec1;
}
.tab.active::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-top: 8px solid #5abec1;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.arrow {
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999999;
  font-size: 20px;
  font-weight: 600;
}

.arrowIcon {
  font-size: 16px;
  color: #999;
}

.arrow:hover {
  background: rgba(90, 190, 193, 0.6);
  .arrowIcon {
    color: #fff;
  }
}

.next {
  border-left: 1px solid #b3b3b3;
}

.timeLine {
  overflow: scroll;
  width: 1200px;
  overflow-x: auto;
  display: flex;
}

.box {
  padding: 153px 0;
  position: relative;
}

.linebox {
  width: 255px;
  height: 1px;
  background: #5abec1;
  position: relative;
}

.linebox::after {
  content: '';
  position: absolute;
  top: -8px;
  left: 120px;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 4px solid #5abec1;
  border-radius: 50%;
  box-sizing: border-box;
}
.content {
  width: 218px;
  height: 75px;
  margin: 0 auto 6px;
  font-size: 18px;
  color: #333;
  text-align: left;
}
.month {
  text-align: center;
  color: #5abec1;
  font-weight: 500;
  font-size: 18px;
}

.topbox {
  position: absolute;
  width: 255px;
  padding-top: 20px;
  height: 139px;
  top: 0;
  background: url('image/top.png') no-repeat;
  background-size: 100%;
  left: 0;
}
.text-ellipsis-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
  overflow: hidden;
}
.bottombox {
  position: absolute;
  width: 255px;
  height: 139px;
  bottom: 0;
  background: #5abec1;
  background: url('image/bottom.png') no-repeat;
  background-size: 100%;
  left: 0;
}
.bottomMonth {
  margin: 15px 0 6px;
}
</style>
